import type { RoleId } from 'types/auth'

export const BCKS_GTK: RoleId = 'bcks_gtk'
export const BCKS_ADMIN: RoleId = 'bcks_admin'
export const WARTEK_USER_ROLE: RoleId = 'wartek_user_role'

export const DEFAULT_ALLOWED_ROLES: RoleId[] = [
  BCKS_ADMIN,
  BCKS_GTK,
  WARTEK_USER_ROLE,
]
export const BCKS_ADMIN_ROLES: RoleId[] = [BCKS_ADMIN]

export const roleNames = {
  [BCKS_GTK]: 'bcks_gtk',
  [WARTEK_USER_ROLE]: 'wartek_user_role',
}

const roles = {
  BCKS_GTK,
  BCKS_ADMIN,
  WARTEK_USER_ROLE,
}

export default roles
