import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import type { Provider } from 'next-auth/providers'
import { signOut, useSession } from 'next-auth/react'
import React from 'react'
import type { ActiveRole, Session, UserGroup } from 'types/auth'

import { useToast } from '@wartek-id/toast'

import { useAsync, useLocalStorage, usePermission } from 'utils/hooks'

import { baseUrl } from 'configs/api'
import { CAR_TOOL_SESSION_STORAGE_KEY } from 'configs/auth'
import { DEFAULT_ALLOWED_ROLES, roleNames } from 'configs/roles'
import { NONLOGIN_PATHS } from 'configs/routes'

import { AuthContext } from './AuthContext'

function isProtectedRoutes(pathname: string): boolean {
  return !NONLOGIN_PATHS.includes(pathname)
}

function getActiveRole(groups: UserGroup[]): ActiveRole {
  const group = groups.filter((group) =>
    DEFAULT_ALLOWED_ROLES.includes(group.name)
  )[0]
  return {
    value: group?.name,
    name: roleNames[group?.name],
  }
}

async function fetchToken(idToken: string, provider: string): Promise<Session> {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `${baseUrl}/teachers/v1alpha2/login`,
      method: 'POST',
      data: {
        grantType: provider,
        token: idToken,
      },
    }
    const { data } = (await axios(requestConfig)) as AxiosResponse<{
      data: Session
    }>

    const payload = data.data
    const groups = payload.user.groups.map((group) => group.name)
    const { email } = payload.user
    // TODO: find a way to handle this
    if (
      email.includes('@wartek.belajar.id') ||
      email.includes('@testing.belajar.id')
    ) {
      groups.push('wartek_user_role')
    }

    const isUserAllowed = DEFAULT_ALLOWED_ROLES.some((role) =>
      groups.includes(role)
    )
    if (isUserAllowed) {
      return payload
    }
    throw {
      message: 'Akun Anda tidak memiliki akses untuk aplikasi ini',
    }
  } catch (error) {
    throw error
  }
}

export function PermissionGuard({ children }) {
  const router = useRouter()
  const { checkPermissions, currentPathPermissions } = usePermission()
  const permitted = checkPermissions(currentPathPermissions())
  if (permitted) {
    return children
  }
  router.replace({
    pathname: '/404',
  })
  return null
}

export function AuthGuard(props) {
  const router = useRouter()
  const protectedRoutes = isProtectedRoutes(router.pathname)
  const toast = useToast()
  const { data: session, status: sessionStatus } = useSession()
  const { data, status, error, run } = useAsync<Session, AxiosError>()

  const carToolUserSession = useLocalStorage(CAR_TOOL_SESSION_STORAGE_KEY, null)
  const account: Provider | any = session
  const isSessionLoading = sessionStatus === 'loading'
  const hasUser: boolean =
    !!carToolUserSession && Object.keys(carToolUserSession).length > 0

  const logoutFn = async () => {
    await invalidateGuruToken()
    await carToolUserSession.remove()
    await signOut({ callbackUrl: '/login' })
  }

  const invalidateGuruToken = React.useCallback(async () => {
    const guruToken = carToolUserSession?.data?.guruToken
    const path = '/teachers/v1alpha2/logout'
    if (hasUser) {
      try {
        const { data } = (await axios({
          url: `${baseUrl}${path}`,
          method: 'POST',
          data: {
            // @ts-ignore
            grant_type: session?.provider,
            // @ts-ignore
            token: session?.id_token,
          },
          headers: {
            Authorization: `Bearer ${guruToken}`,
          },
        })) as AxiosResponse

        return data
      } catch (error) {
        return Promise.resolve()
      }
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUser, session])

  React.useEffect(() => {
    if (account && status === 'idle') {
      run(fetchToken(account.idToken, account.provider))
    }
  }, [account, status, run])

  React.useEffect(() => {
    if (data) {
      // TODO: find a way to handle this
      const { email } = data.user
      if (
        email.includes('@wartek.belajar.id') ||
        email.includes('@testing.belajar.id')
      ) {
        data.user.groups.push({
          id: `temp_user_group_${email}`,
          name: 'wartek_user_role',
        })
      }

      carToolUserSession.set({
        ...data,
      })
    }
    // eslint-disable-next-line
  }, [data])

  React.useEffect(() => {
    const protectedRoutes = isProtectedRoutes(router.pathname)
    if (!!error && protectedRoutes) {
      toast({
        message: error.message,
      })
      setTimeout(() => {
        logoutFn()
      }, 2000)
    }
    // eslint-disable-next-line
  }, [error])

  const context = {
    activeRole: !!carToolUserSession.data
      ? getActiveRole(carToolUserSession.data.user.groups)
      : null,
    carToolUserSession: carToolUserSession?.data ?? null,
    logout() {
      logoutFn()
    },
  }

  if (isSessionLoading) {
    return null
  }

  if (protectedRoutes) {
    if (carToolUserSession.data) {
      return (
        <AuthContext.Provider value={context} {...props}>
          <PermissionGuard {...props} />
        </AuthContext.Provider>
      )
    }

    router.push({
      pathname: '/login',
      query: {
        callbackUrl: router.asPath,
      },
    })
    return null
  }

  return <AuthContext.Provider value={context} {...props} />
}
